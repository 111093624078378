// User Menu
import './vendor/jquery-accessibleMegaMenu.js';
import { amplify } from './acs-amplify.js';
export function usermenu() {
  if (typeof acs.ui === 'undefined') {
    acs.ui = {};
  }
  acs.ui.hasOldMenu = !!document.getElementById('my-menu');

  acs.userMenu = {};
  acs.userMenu.init = function () {
    acs.ui.hasOldMenu = !!document.getElementById('my-menu');
    acs.ui.hasNewMenu = !!document.getElementById('member-menu');

    if (!acs.ui.hasOldMenu && !acs.ui.hasNewMenu) {
      return;
    }

    acs.userMenu.build();

    if (window.matchMedia('(max-width:767px)').matches) {
      acs.userMenu.build();
      acs.userMenu.mobile();
    } else if (window.matchMedia('(min-width:768px)').matches && !document.querySelector('html').classList.contains('desktopMenuOn')) {
      acs.userMenu.build();
      acs.userMenu.desktop();
    }

    window.matchMedia('(max-width:767px)').addEventListener('change', function (event) {
      if (event.matches) {
        acs.userMenu.build();
        acs.userMenu.mobile();
      }
    });

    window.matchMedia('(min-width:768px)').addEventListener('change', function (event) {
      if (event.matches && !document.querySelector('html').classList.contains('desktopMenuOn')) {
        acs.userMenu.build();
        acs.userMenu.desktop();
      }
    });
  };

  acs.userMenu.build = function () {
    if (acs.ui.hasOldMenu || acs.ui.hasNewMenu) {
      var memberMenuTarget = acs.ui.hasOldMenu ? document.getElementById('my-menu') : document.getElementById('member-menu');

      var userMenuHTML = '';
      userMenuHTML += '<ul class="members js--member-menu" role="menu">';
      userMenuHTML +=
        '<li class="js--member-menu__item" role="none"><a class="hat__button--member" role="menuitem" href=' +
        acs.sso.acsDonateUrl +
        '>Donate</a></li>';
      userMenuHTML +=
        '<li class="js--member-menu__item" role="none"><a class="hat__button--member hat__button--highlight" role="menuitem" href=' +
        acs.sso.acsMembershipUrl +
        '>Membership</a></li>';
      userMenuHTML += '<li class="js--member-menu__item" role="none">';
      if (!!acs.user.loggedin) {
        userMenuHTML +=
          '<a href="#" id="my-account-button" class="my-account__button logged-in" role="menuitem" aria-haspopup="true" aria-owns="my-account-menu" aria-controls="my-account-menu" aria-expanded="false" aria-label="Login menu, you are logged in as ' +
          acs.user.fullname +
          '"></a>';
      } else {
        userMenuHTML +=
          '<a href="#" id="my-account-button" class="my-account__button" role="menuitem" aria-haspopup="true" aria-owns="my-account-menu" aria-controls="my-account-menu" aria-expanded="false" aria-label="Login menu, you are not logged in"></a>';
      }
      userMenuHTML +=
        '<div id="my-account-menu" class="my-account js--member-menu__sub" aria-expanded="false" aria-hidden="true" aria-expanded="false" aria-labelledby="my-account-button" role="group">';
      if (!!acs.user.loggedin) {
        userMenuHTML += '<h3 class="my-account__welcome">Welcome, ' + acs.user.firstname + ' ' + acs.user.lastname + '</h3>';
      } else {
        userMenuHTML += '<a href="' + acs.sso.loginUrl + '" class="my-account__login">Log In</a>';
      }
      userMenuHTML += '<h4 class="my-account__header"><a class="my-account-header__link">Your ACS Account</a></h4>';
      userMenuHTML += '<ul class="my-account__list">';
      if (!!acs.sso.acctManagementUrl) {
        userMenuHTML +=
          '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.acctManagementUrl + '" class="my-account__link">Manage Account</a></li>';
      }
      if (!!acs.user.loggedin && !acs.user.hasrenewal) {
        userMenuHTML += '';
      } else {
        userMenuHTML +=
          '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.omrUrl + '" class="my-account__link">Renew ACS Membership</a></li>';
      }
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' +
        (!!acs.user.loggedin ? acs.sso.acctManagementUrl : acs.sso.changeContact) +
        '" class="my-account__link">Change Contact Info</a></li>';
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' +
        acs.sso.emailSubscription +
        '" class="my-account__link">Manage Email Preferences</a></li>';
      if (!!acs.user.isacsnetworkmember || !acs.user.loggedin) {
        userMenuHTML +=
          '<li class="my-account__item" role="menuitem"><a href="' +
          acs.sso.acsNetworkUrl +
          '" class="my-account__link">' +
          (!acs.user.loggedin ? 'ACS Network' : 'Access ACS Network') +
          '</a></li>';
      } else {
        userMenuHTML +=
          '<li class="my-account__item" role="menuitem"><a href="' +
          acs.sso.acsNetworkOptInUrl +
          '" class="my-account__link">Join ACS Network</a></li>';
      }
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' +
        acs.sso.techDivision +
        '" class="my-account__link">ACS Divisions</a></li>';
      if (!!acs.user.loggedin) {
        userMenuHTML += '<a href="' + acs.sso.localLogout + '" class="my-account__link">Log Out</a>';
      }
      userMenuHTML += '</ul>';
      userMenuHTML += '<h4 class="my-account__header"><a class="my-account-header__link">Quick Access</a></h4>';
      userMenuHTML += '<ul class="my-account__list">';
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.acsDonateLandingUrl + '" class="my-account__link">Donate</a></li>';
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.acsVolunteerUrl + '" class="my-account__link">Volunteer</a></li>';
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' +
        acs.sso.acsMembershipBenefitsUrl +
        '" class="my-account__link">ACS Member Benefits</a></li>';
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.acsStoreUrl + '" class="my-account__link">ACS Store</a></li>';
      userMenuHTML += '</ul>';
      userMenuHTML += '</div>';
      userMenuHTML += '</li>';
      userMenuHTML += '</ul>';

      if (acs.ui.hasOldMenu) {
        userMenuHTML = '<div>';
        userMenuHTML +=
          '<div id="joinLink" class="donate-link" role="menuitem" tabIndex="-1"><a href="' + acs.sso.acsDonateUrl + '">Donate</a></div>';
        userMenuHTML +=
          '<div id="membershipLink" class="membership-link" role="menuitem" tabIndex="-1"><a href="' +
          acs.sso.acsMembershipUrl +
          '">Membership</a></div>';
        userMenuHTML += '<div class="my-account-section">';
        userMenuHTML +=
          '<button id="myAccountButton" type="button" tabIndex="0" class="dropdown-toggle login-link" class="' +
          (!!acs.user.loggedin ? 'account-logged-in' : '') +
          '" data-toggle="dropdown" aria-haspopup="true" aria-owns="myAccountMenu" aria-controls="myAccountMenu" aria-expanded="false" aria-label="' +
          (!!acs.user.loggedin ? 'Login menu, you are logged in as ' + acs.user.fullname : 'Login menu, you are not logged in') +
          '">' +
          (!!acs.user.fullname ? acs.user.fullname : '<span>My Account</span>') +
          '</button>';
        userMenuHTML +=
          '<div id="myAccountMenu" class="dropdown-menu" aria-expanded="false" aria-labelledby="loginLink" aria-hidden="true" role="menu" tabIndex="-1">';
        userMenuHTML += '<div class="menu-container">';
        if (!acs.user.loggedin) {
          userMenuHTML += '<div id="loginLink"><a href="' + acs.sso.loginUrl + '" class="btn-primary btn-block">Log In</a></div>';
        }
        userMenuHTML += '<div class="menu-layout">';
        if (acs.user.loggedin) {
          userMenuHTML += '<h3 class="mobile-title">Welcome, ' + acs.user.firstname + ' ' + acs.user.lastname + '</h3>';
        }
        userMenuHTML += '<div class="menu-col">';
        userMenuHTML += '<h4>Quick Access</h4>';
        userMenuHTML += '<ul>';
        userMenuHTML += '<li class="quick-access-link" role="menuitem" tabIndex="-1">';
        userMenuHTML += '<a href="' + acs.sso.acsDonateLandingUrl + '">Donate</a>';
        userMenuHTML += '</li>';
        userMenuHTML += '<li class="quick-access-link" role="menuitem" tabIndex="-1">';
        userMenuHTML += '<a href="' + acs.sso.acsVolunteerUrl + '">Volunteer</a>';
        userMenuHTML += '</li>';
        userMenuHTML += '<li class="quick-access-link" role="menuitem" tabIndex="-1">';
        userMenuHTML += '<a href="' + acs.sso.acsMembershipBenefitsUrl + '">ACS Member Benefits</a>';
        userMenuHTML += '</li>';
        userMenuHTML += '<li class="quick-access-link" role="menuitem" tabIndex="-1">';
        userMenuHTML += '<a href="' + acs.sso.acsStoreUrl + '">ACS Store</a>';
        userMenuHTML += '</li>';
        userMenuHTML += '</ul>';
        userMenuHTML += '</div>';
        userMenuHTML += '<div class="menu-col">';
        userMenuHTML += '<h4>My Account</h4>';
        userMenuHTML += '<ul>';
        if (acs.sso.acctManagementUrl) {
          userMenuHTML +=
            '<li id="myAccountLink" class="myaccount-link" role="menuitem" tabIndex="-1"><a href="' +
            acs.sso.acctManagementUrl +
            '">Manage Account</a></li>';
        }
        if (acs.user.loggedin && !acs.user.hasrenewal) {
          userMenuHTML += '';
        } else {
          userMenuHTML +=
            '<li id="renewLink" class="renew-link" role="menuitem" tabIndex="-1"><a href="' + acs.sso.omrUrl + '">Renew ACS Membership</a></li>';
        }
        userMenuHTML +=
          '<li class="join-link" role="menuitem" tabIndex="-1"><a href="' +
          (!!acs.user.loggedin ? acs.sso.acctManagementUrl : acs.sso.changeContact) +
          '">Change Contact Info</a></li>';
        userMenuHTML +=
          '<li class="join-link" role="menuitem" tabIndex="-1"><a href="' + acs.sso.emailSubscription + '">Manage Email Preferences</a></li>';
        if (acs.user.isacsnetworkmember || !acs.user.loggedin) {
          userMenuHTML +=
            '<li id="acsnetworkLink" class="acsnetwork-link" role="menuitem" tabIndex="-1"><a href="' +
            acs.sso.acsNetworkUrl +
            '">' +
            (!acs.user.loggedin ? 'ACS Network' : 'Access ACS Network') +
            '</a></li>';
        } else {
          userMenuHTML +=
            '<li id="acsnetworkLink" class="acsnetwork-link" role="menuitem" tabIndex="-1"><a href="' +
            acs.sso.acsNetworkOptInUrl +
            '">Join ACS Network</a></li>';
        }
        userMenuHTML += '<li class="join-link" role="menuitem" tabIndex="-1"><a href="' + acs.sso.techDivision + '">ACS Divisions</a></li>';
        if (acs.user.loggedin) {
          userMenuHTML +=
            '<li class="logout-link" id="logoutLink" role="menuitem" tabIndex="-1"><a href="' + acs.sso.localLogout + '">Log Out</a></li>';
        }
        userMenuHTML += '</ul>';
        userMenuHTML += '</div>';
        userMenuHTML += '</div>';
        userMenuHTML += '</div>';
        userMenuHTML += '</div>';
        userMenuHTML += '</div>';
        userMenuHTML += '</div>';
      }

      memberMenuTarget.innerHTML = userMenuHTML;
    }
  };

  acs.userMenu.desktop = function () {
    if (acs.ui.hasNewMenu) {
      if ($.fn.accessibleMegaMenu) {
        $('#member-menu').accessibleMegaMenu({
          /* prefix for generated unique id attributes, which are required
						 to indicate aria-owns, aria-controls and aria-labelledby */
          uuidPrefix: 'js--member-menu',

          /* css class used to define the megamenu styling */
          menuClass: 'js--member-menu',

          /* css class for a top-level navigation item in the megamenu */
          topNavItemClass: 'js--member-menu__item',

          /* css class for a megamenu panel */
          panelClass: 'js--member-menu__sub',

          /* css class for a group of items within a megamenu panel */
          panelGroupClass: 'js--member-menu-sub__list',

          /* css class for the hover state */
          hoverClass: 'hover',

          /* css class for the focus state */
          focusClass: 'focus',

          /* css class for the open state */
          openClass: 'open',
        });
      }
    }
  };

  acs.userMenu.mobile = function () {
    if ($.fn.accessibleMegaMenu) {
      $('#member-menu').accessibleMegaMenu.destroy;
    }

    let myAccountButton = acs.ui.hasOldMenu ? document.querySelector('#myAccountButton') : document.querySelector('.my-account__button');

    myAccountButton.removeEventListener('click', showMenu);
    myAccountButton.addEventListener('click', showMenu);

    function showMenu() {
      if (!myAccountButton.classList.contains('showit')) {
        if (!!document.querySelector('.acsHeader')) {
          document.querySelector('.acsHeader').classList.remove('menu-on');
        }
        document.querySelectorAll('.showit').forEach(element => element.classList.remove('showit'));
        document.querySelectorAll('.hide').forEach(element => element.classList.remove('hide'));
        if (!!document.querySelector('.menu')) {
          document.querySelector('.menu').classList.remove('search-on');
        }
        document.querySelector('body').classList.remove('noscroll');
      }
      if (!!document.querySelector('.hat__menu-back')) {
        document.querySelector('.hat__menu-back').classList.remove('showit');
      }
      if (!!document.querySelector('.menu__logo')) {
        document.querySelector('.menu__logo').classList.remove('hide');
      }
      document.querySelector('body').classList.toggle('noscroll');
      if (!!document.querySelector('.my-account')) {
        document.querySelector('.my-account').classList.toggle('showit');
      }
      myAccountButton.classList.toggle('showit');
    }
  };

  var methods = {
    init: function () {
      acs.userMenu.init();

      amplify.subscribe('sso.status.changed', function (newData) {
        acs.userMenu.init();
      });
    },
    update: function () {
      acs.userMenu.init();
    },
  };

  // extend the acs namespace with the login menu UI
  Object.assign(acs.ui, { loginMenu: methods });
}
usermenu();
